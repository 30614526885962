<template lang="pug">
div.view_container(v-bind:class="{view_container_small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  div.view_title
    span Apart, But Together
    br
    span.subtitle Let's make more meaningful messages
    br
  div.description
    span WishWell started from my experiences inviting friends to make group videos for the people we care about. I've seen how powerful receiving a video of messages from friends can be when the message is personal, genuine, and filled with meaning. But, I also saw how much time, effort, and skill was needed to produce those videos and how inaccessible it was for most people.
    br
    br
    span WishWell builds on those experiences and learnings to make it as simple and easy to create shared video experiences as it would be to post on social media.
  div.bio
    img(src="https://wishwell-site-content.s3.amazonaws.com/ben_w_dog_profile.jpg")
    div.bio_info
      div.name Ben Chow
      div.title co-founder
      div.description
        span product designer & serial entrepreneur
    div.clear
</template>

<script>
/* global gtag */

import config from '../appConfig'
import Header from '../components/Header'

export default {
  name: 'About',
  props: {
  },
  components: {
    Header,
  },
  data() {
    return {
      isSmallScreen: config.isSmallScreen(),
    }
  },
  apollo: {

  },
  methods: {
    next: function(route) {
      this.$router.push(route)
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    }
  },
  mounted: function () {
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/about'})
  }
}
</script>

<style scoped>
  .view_container {
    font-family: gibsonregular;
    font-size: 26px;
  }


  .subtitle {
    font-family: gibsonregular;
    font-size: 26px;
  }

  .description {
    font-family: 'Times New Roman', Times, serif;
    font-size: 22px;
    color: #5a5d61;
  }

  .bio {
    margin: 60px 0;
    padding: 30px 30px;
    background-color: #f6f7f8;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    float: left;
  }
  .bio img {
    width: 48px;
    height: 48px;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    overflow: hidden;
    float: left;
    margin: 0px 12px 12px 0px;
  }

  .bio_info {
    float: left;
    max-width: 450px;
  }

  .bio_info .name {
    font-family: gibsonsemibold;
    font-size: 20px;
  }

  .bio_info .title {
    font-family: gibsonregular;
    font-size: 18px;
  }

  .bio_info .description {
    font-family: gibsonregular;
    font-size: 18px;
    color: RGBA(148, 154, 172, 1.00);
  }
  .description a {
    color: RGBA(148, 154, 172, 1.00);
  }
</style>